import { Box, Typography } from '@mui/material';
import React from 'react';

const ConsentDetails = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      component="section"
      flexDirection="column"
      m={8}
    >
      <Typography variant="h5" gutterBottom>
        <b>Ethics Information and Consent</b>
      </Typography>
      <p>
        <b>Legal Modelling Tool Web Application</b>
      </p>

      <Typography variant="h6" gutterBottom>
        <b>Project overview</b>
      </Typography>
      <p>
        The Legal Modelling Tool is a prototype web application developed and funded by CSIRO’s Data61 Software and
        Computational Systems (SCS) research program.
      </p>

      <Typography variant="h6" gutterBottom>
        <b>Your consent/</b>
      </Typography>
      <p>
        In order to use the Legal Modelling Tool we need to ensure our users have met the requirements of informed
        consent. Consent is provided by reading and agreeing to the conditions of use as outlined in this Ethics Consent
        Form and the following documents:
      </p>

      <ol>
        <li>
          <a href="terms_of_use.html">Terms of Use</a>
        </li>
        <li>
          <a href="privacy_policy.html">Privacy Policy</a>
        </li>
      </ol>

      <Typography variant="h6" gutterBottom>
        <b>Personal information collected</b>
      </Typography>
      <p>
        You will need to provide an email address and create a password to protect your account during the account
        set-up process. Account creation, management and security is managed by Auth0, a third-party provider.
      </p>

      <Typography variant="h6" gutterBottom>
        <b>How information and feedback will be used and disclosed</b>
      </Typography>
      <p>
        Feedback provided to the development team in writing, video or audio formats may be used to improve the
        technology solutions we develop. Feedback will be kept confidential and will not be shared with third-parties or
        published. CSIRO does not use any information collected for advertising or direct marketing purposes.
      </p>

      <Typography variant="h6" gutterBottom>
        <b>Compensation</b>
      </Typography>
      <p>
        Use of the Legal Modelling Tool is voluntary and you will not receive any compensation for use of the web
        application.
      </p>

      <Typography variant="h6" gutterBottom>
        <b>Privacy</b>
      </Typography>
      <p>
        CSIRO is committed to protecting your privacy in accordance with the Australian Privacy Principles contained in
        the Privacy Act 1988 (Cth). CSIRO’s privacy policy (
        <a href="https://www.csiro.au/en/About/Access-to-information/Privacy">
          https://www.csiro.au/en/About/Access-to-information/Privacy
        </a>
        ) contains information about how you can make a complaint about a breach of the Australian Privacy Principles,
        how CSIRO will deal with any complaints, and information about access to and correction of any personal
        information held by CSIRO.
      </p>

      <Typography variant="h6" gutterBottom>
        <b>Ethical clearance and contacts</b>
      </Typography>
      <p>
        This program has been approved by CSIRO’s Social Science Human Research Ethics Committee in accordance with the
        National Statement on Ethical Conduct in Human Research 2007 (updated 2018). If you have any questions
        concerning your participation in the program please contact the researcher via their contact details below.
        Alternatively, any concerns or complaints about the conduct of this study can be raised with the Executive
        Manager of Social Responsibility and Ethics on (07) 3833 5693 or by email at
        <a href="mailto:csshrec@csiro.au">csshrec@csiro.au</a>.
      </p>

      <Typography variant="h6" gutterBottom>
        <b>Contact information</b>
      </Typography>
      <p>
        If you do not understand any part of this form, or you would like more information, please contact the CSIRO
        Data61 Legal Modelling Tool Research Lead:
      </p>

      <ul style={{ listStyleType: 'none' }}>
        <li>Attention: Mark Staples, Research Lead </li>
        <li>Level 5, 13 Garden Street, Eveleigh NSW 2015 </li>
        <li>Tel: +61 2 9376 2000</li>
      </ul>

      <Typography variant="h6" gutterBottom>
        <b>Your authority and consent</b>
      </Typography>
      <ul>
        <li>Confirm that you have read and understood this Ethics Information and Consent Form</li>
        <li>Give permission to use feedback you provide, and </li>
        <li>Consent to the use and disclosure of your personal information as described above</li>
      </ul>
    </Box>
  );
};

export { ConsentDetails };
